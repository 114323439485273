import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { colors } from '../styles'

const linkStyles = css`
  color: ${colors.chestnutRose};
  text-decoration: none;
  &:hover {
    color: ${colors.rajah};
  }
`

export const InternalLink = styled(Link)`
  ${linkStyles}
`

export const ExternalLink = styled.a`
  ${linkStyles}
`
