import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, breakpoints } from '../styles'

const Wrapper = styled.header`
  font-family: Mulish;
`

const TitleWrapper = styled.div`
  margin-bottom: 20px;
`

const Title = styled.h1`
  text-align: center;
  font-size: 3rem;
  color: ${colors.grannySmith};
  font-family: Heebo;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin: 40px 0 20px;
`

const Subtitle = styled.h2`
  text-align: center;
  font-size: 1rem;
  padding-bottom: 8px;
  font-weight: 400;
`

const NavItems = styled.ul`
  display: flex;
  justify-content: center;
  padding: 15px 75px 30px;
  list-style-type: none;
  font-size: 0.8rem;
`

const StyledLink = styled(props => (
  <Link
    {...props}
    activeStyle={{
      color: colors.grannySmith
    }}
  />
))`
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.mineShaft};
  padding: 15px 12px 0;
  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 15px 4px 0;
  }
  &:hover {
    color: ${colors.grey};
  }
  transition: color 0.14s 0.2s ease-out;
`

const Header = () => (
  <Wrapper>
    <TitleWrapper>
      <Title>Yannis Xenakis</Title>
      <Subtitle>University teacher</Subtitle>
      <Subtitle>Retired European Commission official</Subtitle>
    </TitleWrapper>
    <nav>
      <NavItems>
        <li>
          <StyledLink to='/'>About</StyledLink>
        </li>
        <li>
          <StyledLink to='/teaching'>Teaching</StyledLink>
        </li>
        <li>
          <StyledLink to='/work'>Work</StyledLink>
        </li>
        <li>
          <StyledLink to='/education'>Education</StyledLink>
        </li>
      </NavItems>
    </nav>
  </Wrapper>
)

export default Header
