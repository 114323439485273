import * as React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../styles'
import Email from './svgs/Email'
import { ExternalLink } from './Link'

const Wrapper = styled.footer`
  color: ${colors.tundora};
  padding: 45px 5% 75px;
  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 45px;
  }
  font-family: 'Open Sans';
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
`

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
    cursor: pointer;
    transition: color 170ms ease-in-out;
    color: ${colors.mineShaft};
    &:hover {
      color: ${colors.grey};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <IconsWrapper>
      <a href='mailto:yannis.xenakis@skynet.be' target='_blank'>
        <Email />
      </a>
    </IconsWrapper>
    <div>
      Built by{' '}
      <ExternalLink href='https://ioanna.dev' target='_blank'>
        Ioanna Xenaki
      </ExternalLink>
    </div>
  </Wrapper>
)

export default Footer
