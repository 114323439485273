import * as React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { GlobalStyle } from '../globalStyles'
import Header from './Header'
import Footer from './Footer'
import CookiesBanner from './CookiesBanner'

const Main = styled.main`
  padding: 75px 5%;
`

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => (
  <React.Fragment>
    <GlobalStyle />
    <Helmet>
      <link
        href='https://fonts.googleapis.com/css?family=Mulish:400,700'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Open+Sans:400,700'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Heebo:400'
        rel='stylesheet'
      />
    </Helmet>
    <Header />
    <Main>{children}</Main>
    <Footer />
    <CookiesBanner />
  </React.Fragment>
)

export default Layout
