import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../styles'
import { InternalLink } from './Link'
import Paragraph from './Paragraph'

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div > * {
    display: inline-block;
  }

  color: ${colors.mineShaft};
  background-color: ${colors.silverChalice};
  text-align: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 5rem;
  z-index: 999;
  max-width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  padding: 0;
`

const StyledInternalLink = styled(InternalLink)`
  font-size: 0.8rem;
  padding: 0 3px;
`

const Button = styled.button`
  background-color: ${colors.alabaster};
  color: ${colors.chestnutRose};
  border: none;
  padding: 4px 10px;
  margin-left: 20px;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    color: ${colors.rajah};
  }
`

const CookiesBanner = () => {
  const [hideBanner, setHideBanner] = useState<boolean>(false)
  
  // This is needed because localStorage is not available during server side rendering
  // See https://github.com/gatsbyjs/gatsby/issues/309
  const windowGlobal = typeof window !== 'undefined' && window

  const hasAcceptedCookies = JSON.parse(
    windowGlobal && windowGlobal.localStorage.getItem('cookiesAcceptance')
  )

  const acceptCookies = () => {
    windowGlobal &&
      windowGlobal.localStorage.setItem(
        'cookiesAcceptance',
        JSON.stringify({ value: true })
      )
  }

  return hasAcceptedCookies || hideBanner ? null : (
    <Wrapper>
      <div>
        <StyledParagraph>
          We use cookies to ensure that we give you the best experience on our
          website.
        </StyledParagraph>
        <StyledInternalLink to='/cookies'>Click here</StyledInternalLink>
        <StyledParagraph>for more information.</StyledParagraph>
      </div>
      <Button
        onClick={() => {
          setHideBanner(true)
          acceptCookies()
        }}
      >
        Okay
      </Button>
    </Wrapper>
  )
}

export default CookiesBanner
