export const colors = {
  alabaster: '#FAFAFA',
  tundora: '#474747',
  chestnutRose: '#D15B57',
  mineShaft: '#343434',
  grannySmith: '#899B9F',
  rajah: '#F2B763',
  grey: '#818181',
  silverChalice: '#A6A6A6'
}

export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px'
}
