import * as React from 'react'

type Props = {
  height?: string
  width?: string
  fill?: string
}

const Email = ({ height = '28', width = '28' }: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 299.997 299.997'
    height={height}
    width={width}
  >
    <title>Email</title>
    <path
      fill='currentColor'
      d='M149.996 0C67.157 0 .001 67.158.001 149.997c0 82.837 67.156 150 149.995 150s150-67.163 150-150C299.996 67.158 232.835 0 149.996 0zm.003 52.686l88.763 55.35H61.236l88.763-55.35zm89.869 143.737h-.009c0 8.878-7.195 16.072-16.072 16.072H76.211c-8.878 0-16.072-7.195-16.072-16.072v-84.865c0-.939.096-1.852.252-2.749l84.808 52.883c.104.065.215.109.322.169.112.062.226.122.34.179a8.903 8.903 0 001.847.721c.065.018.13.026.195.041a9.148 9.148 0 002.093.265h.015c.7 0 1.401-.099 2.093-.265.065-.016.13-.023.195-.041a8.99 8.99 0 001.847-.721 9.19 9.19 0 00.34-.179c.106-.06.218-.104.322-.169l84.808-52.883c.156.897.252 1.808.252 2.749v84.865z'
    />
  </svg>
)

export default Email
