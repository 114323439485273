import { createGlobalStyle } from 'styled-components'
import { breakpoints, colors } from './styles'

export const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${colors.alabaster};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    font-size: 17px; 
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 15px;
    }
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 13px;
    }
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  header,
  main, 
  footer {
    max-width: 1118px;
    margin: 0 auto;
    font-family: 'Open Sans';
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  li,
  p {
    margin: 0;
  }
`
